// import Sentry from your framework SDK (e.g. @sentry/react) instead of @sentry/browser
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    release: import.meta.env.VITE_APP_COMMIT,
    environment: import.meta.env.VITE_ENV,

    debug: false,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.25,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    tracesSampleRate: 0.1,
    tracePropagationTargets: [/^https:\/\/stella\.pm/, /^https:\/\/portal\.stella\.pm/],

    integrations: [
        Sentry.browserTracingIntegration({
            enableInp: true,
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.feedbackIntegration({
            colorScheme: "light",
        }),
    ],
});
